import React, {useEffect, useState} from "react";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton, Table, TableBody, TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";
import {Add, Search, TableRowsOutlined} from "@mui/icons-material";
import {DataGrid, GridColDef, deDE} from "@mui/x-data-grid";
import {useFetch} from "../../../hooks/useFetch";
import {ILegalTransaction, ILegalTransactionFullObject} from "../../../Interfaces/ILegalTransaction";
import {ILegalTransactionType} from "../../../Interfaces/ILegalTransactionType";
import {CustomCircularProgress} from "../../generic/CustomCircularProgress";
import {LegalPhaseMain} from "./LegalPhase/LegalPhaseMain";
import {LegalTransactionView} from "./LegalTransactionView";
import {ILegalTransactionProgress} from "../../../Interfaces/ILegalTransactionProgress";
import {FullScreenDialog} from "../../core/FullScreenDialog";
import {useNavigate, useParams} from "react-router-dom";
import {ILegalTransactionMeeting} from "../../../Interfaces/ILegalTransactionMeeting";
import {format} from 'date-fns';
import { castWithZeroTimezone } from "../../core/generic_helper";
import { IDocument } from "../../../Interfaces/IDocument";
import { ProvidedDataOverview } from "../Document/ProvidedDataOverview";
import { HelpOverview } from "./HelpOverview";
import { IConfig } from "../../../Interfaces/IConfig";
import { RequestFileHelp } from "../../core/RequestFileHelp";

const columnsDesktop: GridColDef[] = [
    {
        field: 'idLegalTransaction',
        headerName: 'Nr.',
        width: 90
    },
    {
        field: 'LegalTransactionType',
        headerName: 'Art',
        width: 150
    },
    {
        field: 'LegalTransactionProgress',
        headerName: 'Phase',
        width: 150
    },
    {
        field: 'Title',
        headerName: 'Bezeichnung',
        flex: 1
    },
    {
        field: 'action',
        headerName: 'Aktionen',
        width: 150,
        renderCell: () => <Button variant="contained">Öffnen</Button>
    }
]

const columnsMobile: GridColDef[] = [
    {
        field: 'idLegalTransaction',
        headerName: 'Nr.',
        width: 90
    },
    {
        field: 'Title',
        headerName: 'Bezeichnung',
        flex: 1
    },
    {
        field: 'action',
        headerName: '',
        width: 60,
        renderCell: () => <IconButton><Search/></IconButton>
    }
]


interface IProps {
    configObject: IConfig;
}

  


export const LegalTransactionOverview: React.FC<IProps> = ({configObject}) => {
    const params = useParams();
    const navigation = useNavigate();
    const isDesktop = useMediaQuery('(min-width:600px)');
    //
    const [legalTransactionArray, setLegalTransactionArray, wasSuccessfully] = useFetch<ILegalTransactionFullObject[]>("/legaltransaction");
    const [legalTransactionProgressArray, setLegalTransactionProgress, wasSuccessfullyLegalTransactionProgress] = useFetch<ILegalTransactionProgress[]>("/legaltransactionprogress");
    const [idLegalTransaction, setIdLegalTransaction] = useState<number | null>(
        (params.idLegalTransaction == undefined) ? null : Number(params.idLegalTransaction)
    );
    const [currentLegalTransaction, setCurrentLegalTransaction] = useState<ILegalTransactionFullObject | undefined>(undefined);

    const [meetingArray, setMeetingArray] = useState<ILegalTransactionMeeting[]>([])
    const [meetingsLoaded, setMeetingsLoaded] = useState(false)

    const handleOnClick = (idLegalTransaction: number) => {
        if (legalTransactionArray !== undefined) {
            navigation(`/${idLegalTransaction}`);
            setCurrentLegalTransaction(legalTransactionArray.find(x => x.idLegalTransaction === idLegalTransaction))
        }
    }

    useEffect(() => {
        if (legalTransactionArray !== undefined && !meetingsLoaded) {
            let tempMeetingArray: ILegalTransactionMeeting[] = []
            legalTransactionArray.map(legaltransaction =>
                legaltransaction.LegalTransactionMeetingArray?.map(meeting => {
                        tempMeetingArray.push(meeting)
                    }
                ))
            const currentDate = new Date();
            tempMeetingArray = tempMeetingArray.filter(meeting => new Date(meeting.Meeting_at) > currentDate);
            setMeetingArray([...tempMeetingArray].sort((a, b) => {
                const dateA = new Date(a.Meeting_at).getTime();
                const dateB = new Date(b.Meeting_at).getTime();
                return dateA - dateB;
            }))
            setMeetingsLoaded(true)
        }
    }, [legalTransactionArray, wasSuccessfully]);


    const handleClose = () => {
        navigation(`/`);
        setCurrentLegalTransaction(undefined);
    }

    useEffect(() => {
        if (legalTransactionArray !== undefined && params.idLegalTransaction !== undefined) {
            setCurrentLegalTransaction(legalTransactionArray.find(x => x.idLegalTransaction === idLegalTransaction))
        }
    }, [legalTransactionArray])


    function formatMeetingDate(dateString: string) {
        const date = new Date(dateString);
        // Formatieren als 'DD.MM.YYYY HH:MM'
        return format(date, 'dd.MM.yyyy HH:mm');
    }


    if (!wasSuccessfully || !wasSuccessfullyLegalTransactionProgress) {
        return <>Fehler!</>
    } else if (legalTransactionArray == undefined || legalTransactionProgressArray === undefined || !wasSuccessfully) {
        return <CustomCircularProgress/>
    } else {
        return (
            <>
                <FullScreenDialog
                    isOpen={currentLegalTransaction !== undefined}
                    onClose={handleClose}
                    title="Vorgang"
                >
                    <>
                        {(currentLegalTransaction !== undefined) &&
                            <LegalTransactionView
                                configObject={configObject}
                                key={`idLegalTransaction-${currentLegalTransaction.idLegalTransaction}`}
                                legalTransactionObject={currentLegalTransaction}
                                legalProgressArray={legalTransactionProgressArray}
                                view={params.view}
                                handleClose={handleClose}
                            />
                        }
                    </>
                </FullScreenDialog>



                <Box sx={{mb: 10}}>
                    {(meetingArray.length >= 1) &&
                        <>
                            <Typography variant="h4">
                                Ihre Termine
                            </Typography>
                            <Alert severity="success">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                                <TableCell>Termin</TableCell>
                                                <TableCell>Am</TableCell>
                                                <TableCell>Vorgang</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {meetingArray.map(meeting =>
                                            <TableRow>
                                                <TableCell>{meeting.LegalTransactionMeeting}</TableCell>
                                                <TableCell>{new Date(castWithZeroTimezone(meeting.Meeting_at)!).toLocaleString()}</TableCell>
                                                <TableCell>{legalTransactionArray.find(x => x.idLegalTransaction == meeting.idLegalTransaction)?.Title}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                            </Alert>
                        </>
                    }
                    {(meetingArray.length == 0) &&
                        <>
                            <Typography variant="h4">
                                Meine Termine
                            </Typography>
                            <Alert severity="info">
                                Zurzeit sind keine Termine vereinbart.
                            </Alert>
                            <Box></Box>
                        </>
                    }
                </Box>

                {/*
                    <ProvidedDataOverview/>
                 */}
                


                <Typography variant="h4">
                    Meine Vorgänge
                    <Box sx={{float: "right"}}>
                        <HelpOverview configObject={configObject}/>
                    </Box>
                </Typography>


                <Box sx={{m: (isDesktop) ? 2 : 0, mt: 2}}>
                    <DataGrid
                        autoHeight
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={legalTransactionArray}
                        columns={(isDesktop) ? columnsDesktop : columnsMobile}
                        getRowId={(row) => row.idLegalTransaction}
                        onRowClick={(params, event, details) => handleOnClick(Number(params.row.idLegalTransaction))}
                    />

                    <Grid container spacing={2}>
                        <Grid item sm={12}>

                        </Grid>

                        {/* (currentLegalTransaction !== undefined) &&
                        <Grid item sm={7}>
                            <LegalTransactionView
                                key={`idLegalTransaction-${currentLegalTransaction.idLegalTransaction}`}
                                legalTransactionObject={currentLegalTransaction}
                                legalProgressArray={legalTransactionProgressArray}
                            />
                        </Grid>
                    */}
                    </Grid>
                </Box>
            </>
        )
    }

}