import React, { useState } from "react";
import { ILegalTransactionFullObject } from "../../../Interfaces/ILegalTransaction";
import { ILegalRole } from "../../../Interfaces/ILegalRole";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { IContactInvite } from "../../../Interfaces/IContactInvite";
import { ISystem } from "../../../Interfaces/ISystem";
import { uploadFetch } from "../../../hooks/useFetch";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { getIsEmail } from "../../core/generic_helper";


interface IProps {
    legalTransactionObject: ILegalTransactionFullObject;
    currentPermission: ILegalRole;
}



export const LegalTransactionInvitePerson:React.FC<IProps> = (props) => {
    const newInvitePerson = () => {
        return {
            idContactInvite: -1,
            Email: "",
            From_idContact: props.legalTransactionObject.idContact,
            LegalTransaction_idLegalTransaction: props.legalTransactionObject.idLegalTransaction,
            isAssumed: null,
            TempToken: null,
            Timestamp: null,
            To_idContact: null,
            LegalRole_idLegalRole: -1,
            Text: ""
        } as IContactInvite
    }

    const [isDialogOpen,setIsDialogOpen] = useState(false);
    const [newInvite, setNewInvite] = useState(newInvitePerson());
    //
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSavedSuccessfuly,setWasSavedSuccessfuly] = useState(true);
    const [isLoading,setIsLoading] = useState(false);


    const handleOpen = () => {
        setWasSaved(false);
        setIsLoading(false);
        setWasSavedSuccessfuly(true);
        setNewInvite(newInvitePerson())
        setIsDialogOpen(true);
    }

    const handleSave = () => {
        
        uploadFetch("contactinvite/invite",true,newInvite,() => {},setWasSavedSuccessfuly,setWasSaved,setIsLoading)
        //uploadFetch("/contactinvite/invite",true,newInvite,() => {})
    }


    const contextTsx = () => {
        if (isLoading) {
            return(
                <DialogContent>
                    <CustomCircularProgress/>
                </DialogContent>
            )
        }
        else if (wasSaved) {
            if (wasSavedSuccessfuly) {
                return(
                    <>
                        <DialogContent>
                        <Alert severity="success">
                        Ihre Einladung wurde verschickt!
                        <br/> Die eingeladene Person erhält innerhalb der nächsten 5 Minuten eine E-Mail.
                    </Alert>
                        </DialogContent>
                        <DialogActions>
                                <Button variant="contained" onClick={() => setIsDialogOpen(false)}>Schließen</Button>
                        </DialogActions>
                    </>
                ) 
            } else {
                return(
                    <>
                        <DialogContent>
                            <Alert severity="error">
                                Es ist ein Fehler aufgetreten!
                                <br/> Die Fehlerinformationen wurden an die <a href="https://www.lextorbyte.de/" target="_blank">LeXtorByte UG (haftungsbeschränkt)</a> übermittelt. 
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                                <Button variant="contained" onClick={() => setIsDialogOpen(false)}>Schließen</Button>
                        </DialogActions>
                    </>
                )
            }
        }
        else {
            return(
                <>
                    <DialogContent>
                        <Box sx={{mt: 2}}/>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="E-Mail-Adresse"
                                    name="string-FirstName"
                                    size="small"
                                    type="email"
                                    required
                                    value={newInvite.Email}
                                    error={newInvite.Email !== "" && !getIsEmail(newInvite.Email)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewInvite({ ...newInvite, Email: event.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Rolle"
                                    name="string-rolle"
                                    size="small"
                                    required
                                    value={(newInvite.LegalRole_idLegalRole < 0) ? "" : newInvite.LegalRole_idLegalRole}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewInvite({ ...newInvite, LegalRole_idLegalRole: Number(event.target.value) })}
                                    fullWidth
                                    select
                                >
                                    {
                                        props.legalTransactionObject.LegalRoleCanInviteArray
                                        .filter(x => x.From_idLegalRole === props.currentPermission.idLegalRole)
                                        .map(x => {
                                            let test = props.legalTransactionObject.LegalRoleArray.find(y => y.idLegalRole === x.To_idLegalRole);

                                            if (test === undefined) {
                                                return <MenuItem key={`idLegalRole-${x.To_idLegalRole}`} value={x.To_idLegalRole*-1}>FEHLER</MenuItem>
                                            }
                                            else {
                                                return <MenuItem key={`idLegalRole-${x.To_idLegalRole}`} value={x.To_idLegalRole}>{test.LegalRole}</MenuItem>
                                            }
                                        }
                                        )
                                    }
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Einladungstext"
                                    name="string-FirstName"
                                    size="small"
                                    value={(newInvite.Text === null) ? "" : newInvite.Text}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewInvite({ ...newInvite, Text: event.target.value })}
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                        <Button variant="contained" disabled={newInvite.Email === "" || !getIsEmail(newInvite.Email) || newInvite.LegalRole_idLegalRole < 0} onClick={handleSave}>Abschicken</Button>
                    </DialogActions>
                </>
            )
        }
    }


    return(
        <>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Weitere Person zum Vorgang einladen</DialogTitle>
                {contextTsx()}
            </Dialog>

            <Button variant="contained" sx={{float: "right"}} onClick={handleOpen}>Person einladen</Button>
        </>
    )
}