import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LegalRequirementDocuments } from './LegalRequirementDocuments';
import {
  ILegalRequirement,
  ILegalRequirementDeadline,
} from '../../../../Interfaces/ILegalRequirement';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { LegalInfoText } from './LegalInfoText';
import { IConfig } from '../../../../Interfaces/IConfig';
import {
  RunningWithErrors,
  AccessTime,
  HourglassFull,
  AlarmOn,
  Gavel,
  Done,
  PriorityHigh,
} from '@mui/icons-material';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalRequirement: ILegalRequirement;
  legalRequirementArray: ILegalRequirement[];
  setLegalRequirementArray: Function;
  isNotDisabled: boolean;
  startDeadlineDate: string | null;
}

export const LegalRequirementRow: React.FC<IProps> = props => {
  const [legalRequirement, setLegalRequirement] = useState(
    props.legalRequirement
  );

  const getCurrentValue = () => {
    if (legalRequirement.isComplete) {
      return 1;
    } else if (legalRequirement.hasError) {
      return 2;
    } else {
      return 0;
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tmpValue = Number(event.target.value);

    if (tmpValue === 0) {
      setLegalRequirement({
        ...legalRequirement,
        isComplete: false,
        hasError: false,
      });
    } else if (tmpValue === 2 && legalRequirement.enableError) {
      setLegalRequirement({
        ...legalRequirement,
        isComplete: false,
        hasError: true,
      });
    } else {
      setLegalRequirement({ ...legalRequirement, isComplete: true });
    }
  };

  useEffect(() => {
    props.setLegalRequirementArray([
      ...props.legalRequirementArray.map(x =>
        x.idLegalRequirement === legalRequirement.idLegalRequirement
          ? legalRequirement
          : x
      ),
    ]);
  }, [legalRequirement]);

  const addDaysToDate = (date: Date, days: number): Date => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // Anzahl der Millisekunden in einem Tag
    const result = new Date(date.getTime() + days * millisecondsPerDay);
    return result;
  };

  const getEarliestTriggerDate = useCallback(
    (deadlineArray: ILegalRequirementDeadline[]) => {
      const highestWeighting = deadlineArray
        .filter(
          deadline =>
            deadline.DeadlineTriggeredAt !== null &&
            deadline.DeadlineTriggeredAt !== undefined
        )
        .reduce((maxWeighting, current) => {
          return current.Weighting > maxWeighting
            ? current.Weighting
            : maxWeighting;
        }, 0);

      const earliestTriggerDate = deadlineArray
        .filter(deadline => {
          return (
            deadline.DeadlineTriggeredAt !== null &&
            deadline.DeadlineTriggeredAt !== undefined
          );
        }) //get all with the highest weighting
        .filter(x => x.Weighting == highestWeighting)
        .reduce((earliestTriggerDate, current) => {
          return new Date(current.DeadlineTriggeredAt!) < earliestTriggerDate
            ? new Date(current.DeadlineTriggeredAt!)
            : earliestTriggerDate;
        }, new Date());

      return earliestTriggerDate;
    },
    []
  );

  const getMaxExceededDays = useCallback(() => {
    const { DeadlineArray, initialProcessingTime } = props.legalRequirement;

    if (DeadlineArray && DeadlineArray.length > 0) {
      const earliestTriggerDate = getEarliestTriggerDate(DeadlineArray);

      const differenceInTime =
        new Date().getTime() -
        addDaysToDate(
          earliestTriggerDate,
          props.legalRequirement.initialProcessingTime || 0
        ).getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24));
    } else if (props.startDeadlineDate) {
      // Prüfe, ob das startDate ein gültiges Datum ist
      const startDateObject = new Date(props.startDeadlineDate);
      if (isNaN(startDateObject.getTime())) {
        console.error('Invalid startDate:', props.startDeadlineDate);
        return props.legalRequirement.initialProcessingTime || 0;
      }

      // Hier wird initialProcessingTime korrekt zum Datum addiert
      const adjustedStartDate = addDaysToDate(
        startDateObject,
        initialProcessingTime || 0
      );

      const currentDate = new Date();
      const differenceInTime =
        currentDate.getTime() - adjustedStartDate.getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24)); // Kein Math.max, damit negative Werte berücksichtigt werden
    }

    return Math.floor(props.legalRequirement.initialProcessingTime || 10);
  }, [getEarliestTriggerDate, props.legalRequirement, props.startDeadlineDate]);

  const hasAtLeastOneDeadlineTriggered = useCallback(() => {
    const { DeadlineArray } = props.legalRequirement;
    if (DeadlineArray && DeadlineArray.length > 0) {
      return DeadlineArray.some(deadline => {
        return (
          deadline.DeadlineTriggeredAt !== null &&
          deadline.DeadlineTriggeredAt !== undefined
        );
      });
    }
    if (props.startDeadlineDate !== null) {
      if (new Date(props.startDeadlineDate) <= new Date()) {
        return true;
      }
    }
    return false;
  }, [props.legalRequirement, props.startDeadlineDate]);

  const [exceededDays, setExceededDays] = useState(getMaxExceededDays());
  const [atLeastOneDeadlineTriggered, setAtLeastOneDeadlineTriggered] =
    useState(hasAtLeastOneDeadlineTriggered());
  const [earliestTriggerDate, setEarliestTriggerDate] = useState(
    getEarliestTriggerDate(props.legalRequirement.DeadlineArray || [])
  );

  useEffect(() => {
    setExceededDays(getMaxExceededDays());
    setAtLeastOneDeadlineTriggered(hasAtLeastOneDeadlineTriggered());
    setEarliestTriggerDate(
      getEarliestTriggerDate(props.legalRequirement.DeadlineArray || [])
    );
  }, [
    getEarliestTriggerDate,
    getMaxExceededDays,
    hasAtLeastOneDeadlineTriggered,
    props.legalRequirement,
    props.startDeadlineDate,
  ]);

  return (
    <>
      <Grid item xs={2} sm={3} lg={1}></Grid>

      <Grid item xs={10} sm={9} lg={11} direction={'row'}>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%' }}
          width={'100%'}
        >
          <Alert
            variant={props.isNotDisabled ? 'standard' : 'outlined'}
            severity={
              legalRequirement.idLegalRequirementState === 40
                ? 'success'
                : legalRequirement.enableError &&
                  legalRequirement.idLegalRequirementState === 30
                ? 'error'
                : "info"
            }
            action={
              <>
                <LegalInfoText currentObject={legalRequirement} />
                <Typography variant='caption'>
                  
                  { (legalRequirement.idLegalRequirementState < 30)
                    ? "Offen"
                    : legalRequirement.LegalRequirementState
                    /*(props.isNotDisabled) 
                            ?
                                <TextField
                                    label="Status"
                                    size="small"
                                    value={getCurrentValue()}
                                    onChange={handleChangeValue}
                                    sx={{width: 200}}
                                    select
                                >
                                    <MenuItem value={0}>Offen</MenuItem>
                                    <MenuItem value={1}>Erledigt</MenuItem>
                                    <MenuItem value={2} disabled={!legalRequirement.enableError}>Fehler</MenuItem>
                                </TextField>
                            : <i style={{marginTop: 2}}>Keine Änderungen möglich</i>
                        */
                  }
                </Typography>
              </>
            }
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Box width={'100%'} display={'flex'} alignItems={'center'}>
              {legalRequirement.LegalRequirement}

              {atLeastOneDeadlineTriggered &&
                props.legalRequirement.idLegalRequirementState < 15 && (
                  <Tooltip
                    title={`${
                      props.legalRequirement.Court !== null &&
                      props.legalRequirement.Court !== undefined
                        ? `Die Maßnahme liegt bei ${props.legalRequirement.CourtRole} ${props.legalRequirement.Court}.`
                        : ''
                    }   
                    ${
                      exceededDays < -1
                        ? Math.abs(exceededDays) !== 1
                          ? `Die vorraussichtliche Fertigstellung erfolgt in ${Math.abs(
                              exceededDays
                            )} Tagen`
                          : `Die vorraussichtliche Fertigstellung erfolgt in ${Math.abs(
                              exceededDays
                            )} Tag`
                        : 'Überfällig. Die Bearbeitung wird vom Notarbüro angemahnt. Eine Rückmeldung ins Notarbüro ist nicht erforderlich.'
                    }`}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{ width: 150 }}
                      ml={4}
                      mr={2}
                    >
                      {exceededDays <= 0 ? (
                        <LinearProgress
                          variant='determinate'
                          color='success'
                          value={
                            (Math.abs(
                              props.legalRequirement.initialProcessingTime +
                                exceededDays
                            ) /
                              props.legalRequirement.initialProcessingTime) *
                            100
                          }
                          sx={{ flexGrow: 1, marginRight: 2 }} // Responsive width with flexGrow
                        />
                      ) : (
                        <LinearProgress
                          variant='determinate'
                          color='warning'
                          value={100}
                          sx={{ flexGrow: 1, marginRight: 2 }} // Responsive width with flexGrow
                        />
                      )}
                    </Box>
                  </Tooltip>
                )}
              {!atLeastOneDeadlineTriggered &&
                props.legalRequirement.idLegalRequirementState < 15 && (
                  <Tooltip title='Die Bearbeitung der Maßnahme hat laut Plan noch nicht begonnen.'>
                    <span>
                      <IconButton disabled>
                        <HourglassFull color='disabled'></HourglassFull>
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              {(props.legalRequirement.idLegalRequirementState === 15 ||
                props.legalRequirement.idLegalRequirementState === 20) && (
                <Tooltip
                  title={
                    'Die Bearbeitung der Maßnahme ist abgeschlossen. Sie befindet sich aktuell in der Überprüfung.'
                  }
                >
                  <span>
                    <IconButton disabled>
                      <Done color='info'></Done>
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {props.legalRequirement.idLegalRequirementState === 30 && (
                <Tooltip
                  title={
                    'Beim Bearbeiten der Maßnahme ist ein Fehler aufgetreten.'
                  }
                >
                  <span>
                    <IconButton>
                      <PriorityHigh color='error'></PriorityHigh>
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <LegalRequirementDocuments
                configObject={props.configObject}
                legalTransactionObject={props.legalTransaction}
                legalRequirementObject={legalRequirement}
                setLegalRequirementObject={setLegalRequirement}
                isNotDisabled={props.isNotDisabled}
              />
              {legalRequirement.sendMail == true && (
                <Typography variant='caption'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(E-Mail wird versendet)
                </Typography>
              )}
            </Box>
          </Alert>
        </Box>
      </Grid>
    </>
  );
};
